/* FITVID https://github.com/davatron5000/FitVids.js */
!function(t){"use strict";t.fn.fitVids=function(e){var i={customSelector:null,ignore:null};if(!document.getElementById("fit-vids-style")){var r=document.head||document.getElementsByTagName("head")[0],a=document.createElement("div");a.innerHTML='<p>x</p><style id="fit-vids-style">.fluid-width-video-wrapper{width:100%;position:relative;padding:0;}.fluid-width-video-wrapper iframe,.fluid-width-video-wrapper object,.fluid-width-video-wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}</style>',r.appendChild(a.childNodes[1])}return e&&t.extend(i,e),this.each(function(){var e=['iframe[src*="player.vimeo.com"]','iframe[src*="youtube.com"]','iframe[src*="youtube-nocookie.com"]','iframe[src*="kickstarter.com"][src*="video.html"]',"object","embed"];i.customSelector&&e.push(i.customSelector);var r=".fitvidsignore";i.ignore&&(r=r+", "+i.ignore);var a=t(this).find(e.join(","));(a=(a=a.not("object object")).not(r)).each(function(){var e=t(this);if(!(e.parents(r).length>0||"embed"===this.tagName.toLowerCase()&&e.parent("object").length||e.parent(".fluid-width-video-wrapper").length)){e.css("height")||e.css("width")||!isNaN(e.attr("height"))&&!isNaN(e.attr("width"))||(e.attr("height",9),e.attr("width",16));var i=("object"===this.tagName.toLowerCase()||e.attr("height")&&!isNaN(parseInt(e.attr("height"),10))?parseInt(e.attr("height"),10):e.height())/(isNaN(parseInt(e.attr("width"),10))?e.width():parseInt(e.attr("width"),10));if(!e.attr("name")){var a="fitvid"+t.fn.fitVids._count;e.attr("name",a),t.fn.fitVids._count++}e.wrap('<div class="fluid-width-video-wrapper"></div>').parent(".fluid-width-video-wrapper").css("padding-top",100*i+"%"),e.removeAttr("height").removeAttr("width")}})})},t.fn.fitVids._count=0}(window.jQuery||window.Zepto);

// Custom JS Application Code
function validate_email_js(email) { var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; if(reg.test(email) == false) { return false; } return true; }
var ellipsisText = function (e, etc) { var wordArray = e.innerHTML.split(" "); while (e.scrollHeight > e.offsetHeight) { wordArray.pop(); e.innerHTML = wordArray.join(" ") + (etc || "..."); } };

function checkHeaderOffset() {
	var $window = $(window);
	var offset = $window.scrollTop();
	if (offset>=30) {
		$('#headerWrapper').addClass('scrolled');
	}else{
		$('#headerWrapper').removeClass('scrolled');
	}
}

function windowOnResize() {
	var myWidth, myHeight;
	if( typeof( window.innerWidth ) == 'number' ) { myWidth = window.innerWidth; myHeight = window.innerHeight; }
	else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) { myWidth = document.documentElement.clientWidth; myHeight = document.documentElement.clientHeight; }
	else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) { myWidth = document.body.clientWidth; myHeight = document.body.clientHeight; }
	browserWidth = myWidth;
	browserHeight = myHeight;
}
function pageScroll() {
	checkHeaderOffset();
}

function scrollToSlide(slideTo) {
	$('body,html').animate({ scrollTop: $('.'+slideTo).offset().top-$('header').height() }, 800);
}

function showFormMessage(messageType) {
	$('.form-wrapper').hide();
	$('.form-message.form-'+messageType).show();
}

function showForm() {
	$('.form-message').hide();
	$('.form-wrapper').show();
}

function sendContactForm() {
	var cName = $('#cName').val();
	var cEmail = $('#cEmail').val();
	var cPhone = $('#cPhone').val();
	var cMessage = $('#cMessage').val();
	var error = false;
	var totalErrors = '';
	var params = '';

	if (cName=='' || cEmail=='' || cPhone=='' || cMessage=='') {
		showFormMessage('error');
		error = true;
	} else {
		if (!validate_email_js(cEmail)) {
			showFormMessage('error');
			error = true;
		}
	}
	if (!error) { sendContactFormToServer(); }
}

function sendContactFormToServer() {
	if (!inAjax) {
		var cName = $('#cName').val();
		var cEmail = $('#cEmail').val();
		var cPhone = $('#cPhone').val();
		var cMessage = $('#cMessage').val();

		var params = '';
		inAjax = true;
		params += 'cName='+encodeURIComponent(cName);
		params += '&cEmail='+encodeURIComponent(cEmail);
		params += '&cPhone='+encodeURIComponent(cPhone);
		params += '&cMessage='+encodeURIComponent(cMessage);

		jQuery.ajax({
			type: "POST",url: baseUrl+'send_form.php',data: params,
			complete: function(XMLHttpRequest, textStatus) {
				var response = XMLHttpRequest.responseText;
				inAjax = false;
				if (response=='success') {
					showFormMessage('success');
					$('#cName').val('');
					$('#cEmail').val('');
					$('#cPhone').val('');
					$('#cMessage').val('');
				}else{
					showFormMessage('error');
				}
			}
		});
	}
}

// 2. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.
function onYouTubeIframeAPIReady() {
	videoPlayer = new YT.Player('player', {
	  playerVars: { autoplay: 1, loop: 1, controls: 1, showinfo: 0, autohide: 1, modestbranding: 1, vq: 'hd1080'},
	  videoId: 'Jcqyv0--mGA',
	  events: {
	    'onReady': onPlayerReady,
	    'onStateChange': onPlayerStateChange
	  }
	});
}

// 3. The API will call this function when the video player is ready.
function onPlayerReady(event) {
	videoPlayer.mute();
	$('.video-wrapper').fitVids();
	//event.target.playVideo();
}

var done = false;
function onPlayerStateChange(event) {}
function stopVideo() { videoPlayer.stopVideo(); }
function startVideo() { videoPlayer.playVideo(); }
function pauseVideo() { videoPlayer.pauseVideo(); }

(function($) {
	"use strict"; // Start of use strict
	$(window).bind('hashchange');
	$(window).trigger('hashchange');
	$(window).scroll( function() { /*disableScroll();*/ pageScroll(); } );
	$(window).resize(function() { windowOnResize(); });
	$(window).ready(function() {
		var myWidth, myHeight;
		if( typeof( window.innerWidth ) == 'number' ) { myWidth = window.innerWidth; myHeight = window.innerHeight; }
		else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) { myWidth = document.documentElement.clientWidth; myHeight = document.documentElement.clientHeight; }
		else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) { myWidth = document.body.clientWidth; myHeight = document.body.clientHeight; }
		browserWidth = myWidth;
		browserHeight = myHeight;

		AOS.init({once: true, disable: function() { return $(window).width()<1024; }});

		//$('.go-top a').click(function () { $('body,html').animate({ scrollTop: 0 }, 800); $(this).blur(); return false; });

		$('.nav-toggle').bind('click',function(ev) {
			if ($('.nav-wrapper').hasClass('opened')) {
				$('.nav-wrapper').fadeOut(0,function() {
					$(this).removeClass('opened');
					$('#headerWrapper').removeClass('opened');
				});
				$(this).removeClass('is-active');
			}else{
				$('#headerWrapper').addClass('opened');
				$('.nav-wrapper').fadeIn(0,function() {
					$(this).addClass('opened');
				});
				$(this).addClass('is-active');
			}

			$(this).blur();
			ev.preventDefault();
		});

		$('.main-nav li a.in-page-link,.main-nav li.in-page-link a').bind('click',function(ev) {
			var element = $($(this).attr('href'));
			if ($('.nav-wrapper').hasClass('opened')) {
				$('.nav-wrapper').fadeOut(0,function() {
					$(this).removeClass('opened');
					$('#headerWrapper').removeClass('opened');
				});
				$('.nav-toggle').removeClass('is-active');
			}
			$('body,html').animate({ scrollTop: element.offset().top-$('header').outerHeight()+10 }, 800);
			$(this).blur(); ev.preventDefault();
		});
		$('.header .logo a').click(function () { $('body,html').animate({ scrollTop: 0 }, 800); $(this).blur(); return false; });

		$('.form-wrapper .form-bottom a.form-submit').bind('click',function(ev) {
			sendContactForm();
			$(this).blur(); ev.preventDefault();
		});

		$('.form-message .close-form-message').bind('click',function(ev) {
			showForm();
			$(this).blur(); ev.preventDefault();
		});

		$('.js-open-video').bind('click',function(ev) {
			var videoID = $(this).data('video');
			$('#video-popup-'+videoID).fadeIn();
			$('body').addClass('in-popup');
			startVideo();
			$(this).blur(); ev.preventDefault();
		});

		$('.video-popup-wrapper a.close-popup').bind('click',function(ev) {
			pauseVideo();
			$(this).closest('.video-popup-wrapper').fadeOut();
			$('body').removeClass('in-popup');
			$(this).blur(); ev.preventDefault();
		});

	});
	$(window).on('load',function() {
		if ($('.lisbon-swiper-container').length) {
			$('.lisbon-swiper-container').each(function() {
				if ($('.lisbon-swiper-container .swiper-slide').length>1) {
					new Swiper('.lisbon-swiper-container', {
						slidesPerView: 1,
						spaceBetween: 0,
						loop: false,
						autoHeight: true,
						navigation: {
							nextEl: '.lisbon-swiper-container .swiper-custom-next',
							prevEl: '.lisbon-swiper-container .swiper-custom-prev',
						},
					});
				}
			});
		}
		if ($('.aroeira-swiper-container').length) {
			$('.aroeira-swiper-container').each(function() {
				if ($('.aroeira-swiper-container .swiper-slide').length>1) {
					new Swiper('.aroeira-swiper-container', {
						slidesPerView: 1,
						spaceBetween: 0,
						loop: false,
						autoHeight: true,
						navigation: {
							nextEl: '.aroeira-swiper-container .swiper-custom-next',
							prevEl: '.aroeira-swiper-container .swiper-custom-prev',
						},
					});
				}
			});
		}
		if ($('.outside-swiper-container').length) {
			$('.outside-swiper-container').each(function() {
				if ($('.outside-swiper-container .swiper-slide').length>1) {
					new Swiper('.outside-swiper-container', {
						slidesPerView: 1,
						spaceBetween: 0,
						loop: false,
						autoHeight: true,
						navigation: {
							nextEl: '.outside-swiper-container .swiper-custom-next',
							prevEl: '.outside-swiper-container .swiper-custom-prev',
						},
					});
				}
			});
		}
		if ($('.inside-swiper-container').length) {
			$('.inside-swiper-container').each(function() {
				if ($('.inside-swiper-container .swiper-slide').length>1) {
					new Swiper('.inside-swiper-container', {
						slidesPerView: 1,
						spaceBetween: 0,
						loop: false,
						autoHeight: true,
						navigation: {
							nextEl: '.inside-swiper-container .swiper-custom-next',
							prevEl: '.inside-swiper-container .swiper-custom-prev',
						},
					});
				}
			});
		}

		if ($('.popup-gallery-wrapper').length) {
			$('.js-open-gallery').bind('click',function(ev) {
				$('#gallery-'+$(this).data('gallery')+' > a').eq(parseInt($(this).data('item'),10)-1).click();
				$(this).blur(); ev.preventDefault();
			});
			$('.popup-gallery-wrapper .gallery-popup').each(function() {
				var galleryPopup = $(this);
				var galleryPopupID = '#'+galleryPopup.attr('id');
				$(galleryPopupID).lightGallery({
					share: false,
					zoom: false,
					counter: false,
					thumbnail: false,
					download: false,
					autoplay: false,
					fullScreen: false,
					videojs: false,
					mode: 'lg-slide',
					selector: 'a'
				});
			});
		}
		//onYouTubeIframeAPIReady();
	});

})(jQuery); // End of use strict

var browserWidth = 0;
var browserHeight = 0;
var inAjax = false;
var videoPlayer;
